import React from 'react'

export default function Footer() {
  return (
   
    <footer className="footer">
 

    <div className="container gutter-top-l gutter-bottom-s">
      <div className="row mb-4">
        <div className="col-12">
          <span className="font-apfel">Oguzcan Turan</span>
          <p className='font-apfel'>Film & Social Media Marketing</p>
        </div>
      </div>

      <div className="row">
        <div className="col-12 d-lg-flex justify-content-between">
          <nav className="footer__navigation mb-2">
            <a className="a footer__navigation-link" href="https://www.instagram.com/oguzcanturan/" rel="noopener noreferrer" target="_blank">Instagram</a>
            <a className="a footer__navigation-link" href="https://www.facebook.com/oguzcan.turan" rel="noopener noreferrer" target="_blank">Facebook</a>
            <a className="a footer__navigation-link" href="https://tr.linkedin.com/in/o%C4%9Fuzcan-turan-343290218?trk=people-guest_people_search-card" rel="noopener noreferrer" target="_blank">Linkedn</a>
          </nav>
        </div>
      </div>

      
    </div>

  </footer>
  )
}
