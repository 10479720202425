import React, { useState, useEffect, useMemo } from 'react';

export default function VideoPage() {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const videos = useMemo(() => [
    {
      id: 1,
      src: "/videos/ShowReel Karma.mp4",
      title: "Oguzcan Turan",
      description: "Showreel 2024",
      details: "This showreel brings together videos I’ve both shot and edited, showcasing a range of creative and technical skills.",
      isVertical: false,
    },
    {
      id: 2,
      src: "/videos/ShowReel BOG.mp4",
      title: "Oguzcan Turan",
      description: "Be Our Guest Series Showreel",
      details: `As part of the Go Türkiye brand, I had the pleasure of working on the "Be Our Guest" project, a comprehensive video series designed to showcase Turkey's culture.`,
      isVertical: false,
    },
    {
      id: 3,
      src: "/videos/SM_Showreel.mp4",
      title: "Oguzcan Turan",
      description: "Social Media Vertical Videos Showreel",
      details: `In 2024, I had the opportunity to create and edit a collection of dynamic vertical videos customized for social media platforms.`,
      isVertical: false,
      cover: "/images/kapak.jpg",
    },
    {
      id: 4,
      src: "/videos/Edit 1.mp4",
      title: "Oguzcan Turan",
      description: "",
      details: "",
      isVertical: true,
    },
    {
      id: 5,
      src: "/videos/Edit 3.mp4",
      title: "Oguzcan Turan",
      description: "",
      details: "",
      isVertical: true,
    },
    {
      id: 6,
      src: "/videos/Edit 4.mp4",
      title: "Oguzcan Turan",
      description: "",
      details: "",
      isVertical: true,
    },
    {
      id: 7,
      src: "/videos/Edit 5.mp4",
      title: "Oguzcan Turan",
      description: "",
      details: "",
      isVertical: true,
    },
    {
      id: 8,
      src: "/videos/Kapadokya_Teaser_Revize_yüksek.mp4",
      title: "Oguzcan Turan",
      description: "",
      details: "",
      isVertical: true,
    },
    {
      id: 9,
      src: "/videos/KB_HBCI_COOL_ISTANBUL_MAIN_240801_60SN_9X16_WEB_YK.mp4",
      title: "Oguzcan Turan",
      description: "",
      details: "",
      isVertical: true,
    },
    {
      id: 10,
      src: "/videos/Mother_earth_v2.mp4",
      title: "Oguzcan Turan",
      description: "",
      details: "",
      isVertical: true,
    },
    {
      id: 11,
      src: "/videos/Summer_is_coming_v2.mp4",
      title: "Oguzcan Turan",
      description: "",
      details: "",
      isVertical: true,
    },
    {
      id: 12,
      src: "/videos/Bozcaaada_Teaser_v3.mp4",
      title: "Oguzcan Turan",
      description: "",
      details: "",
      isVertical: true,
    },
    {
      id: 13,
      src: "/videos/Day of Happiness.mp4",
      title: "Oguzcan Turan",
      description: "",
      details: "",
      isVertical: true,
    },
  ], []);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isVisible]);

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    setIsVisible(true);
  };

  const handleExitFullscreen = () => {
    setIsVisible(false);
    setTimeout(() => {
      setSelectedVideo(null);
    }, 500);
  };

  const handleMouseOver = (e) => {
    if (e.target.readyState >= 3) {
      e.target.play();
    } else {
      e.target.addEventListener(
        'canplay',
        () => {
          e.target.play();
        },
        { once: true }
      );
    }
  };

  const handleMouseOut = (e) => {
    e.target.pause();
    e.target.currentTime = 0;
  };

  const handleNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === videos.filter((video) => video.isVertical).length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? videos.filter((video) => video.isVertical).length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const preloadVideos = () => {
      videos.forEach((video) => {
        const videoElement = document.createElement('video');
        videoElement.src = video.src;
        videoElement.preload = 'auto';
      });
    };

    preloadVideos();
  }, [videos]);

  useEffect(() => {
    const handleAutoPlayOnMobileAndTablet = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 1024) {
        const allVideos = document.querySelectorAll('video');
        allVideos.forEach((video) => {
          video.play().catch((error) => console.log('Autoplay failed:', error));
        });
      }
    };

    handleAutoPlayOnMobileAndTablet();

    window.addEventListener('resize', handleAutoPlayOnMobileAndTablet);

    return () => {
      window.removeEventListener('resize', handleAutoPlayOnMobileAndTablet);
    };
  }, []);

  const visibleVideos = () => {
    const screenSize = window.innerWidth;
    const verticalVideos = videos.filter((video) => video.isVertical);

    if (screenSize < 768) {
      return [verticalVideos[currentIndex]];
    }

    if (screenSize >= 768 && screenSize < 1024) {
      const nextIndex = (currentIndex + 1) % verticalVideos.length;
      return [verticalVideos[currentIndex], verticalVideos[nextIndex]];
    }

    const nextIndex1 = (currentIndex + 1) % verticalVideos.length;
    const nextIndex2 = (currentIndex + 2) % verticalVideos.length;
    return [verticalVideos[currentIndex], verticalVideos[nextIndex1], verticalVideos[nextIndex2]];
  };

  const videoPageClasses = isVisible
    ? 'opacity-100 blur-0 transition-opacity transition-blur duration-500 ease-in-out mt-12'
    : 'opacity-0 blur-md transition-opacity transition-blur duration-500 ease-in-out';

  if (selectedVideo) {
    return (
      <div className={`fixed inset-0 bg-black flex flex-col items-center justify-start z-50 transition-all duration-500 ${videoPageClasses}`} 
        style={{ paddingTop: '1.5rem', overflowY: 'auto', paddingBottom: '3rem' }}
      >
        <video className={`${selectedVideo.isVertical ? 'h-full w-full' : 'w-8/12 h-auto max-w-6xl'}`}
          src={selectedVideo.src}
          autoPlay
          controls
          style={{ marginBottom: '3rem' }}
        />
        <div className="text-center text-white mt-4">
          <h2 className="text-3xl font-apfel">{selectedVideo.title}</h2>
          <p className="text-5xl font-apfel font-bold">{selectedVideo.description}</p>
        </div>
        <div className="text-white mt-4 max-w-4xl p-4 rounded-lg">

          <p className="text-lg md:text-xl leading-relaxed text-center">
            {selectedVideo.details}
          </p>
        </div>
        <button onClick={handleExitFullscreen} className="mt-8 px-4 py-2 text-gray-200 bg-black-800 hover:bg-gray-700 rounded border border-gray-600">

          Exit
        </button>
      </div>
    );
  }

  return (
    <>
     <section className="flex flex-col items-start relative py-8 px-6 md:px-16 bg-black text-white">
        <video
          className="w-full md:w-3/4 max-w-4xl h-auto mt-6 cursor-pointer"
          src={videos[0]?.src || ''}
          alt="Showreel 2024"
          onClick={() => handleVideoClick(videos[0])}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          preload="auto"
          muted
          loading="lazy"
        />
        <div className="absolute bottom-16 left-4 md:left-8">
          <h2 className="text-2xl md:text-3xl font-apfel tracking-tighter font-bold">2024</h2>
          <p className="text-3xl md:text-5xl font-apfel tracking-tighter font-bold">Showreel</p>
        </div>
      </section>

      <section className="flex flex-col items-end relative py-8 px-6 md:px-16 bg-black text-white">
       <video className="w-full md:w-3/4 max-w-4xl h-auto mt-6 cursor-pointer"
          src={videos[1]?.src || ''}
          alt="Be Our Guest Series Showreel"
          onClick={() => handleVideoClick(videos[1])}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          preload="auto"
          muted
          loading="lazy"
        />
        <div className="absolute bottom-16 right-4 md:right-8">
          <h2 className="text-2xl md:text-3xl font-apfel tracking-tighter font-bold">Be Our Guest</h2>
          <p className="text-3xl md:text-5xl font-apfel tracking-tighter font-bold">Series Showreel</p>
        </div>
      </section>

      <section className="flex flex-col items-start relative py-8 px-6 md:px-16 bg-black text-white">
        <div className="relative cursor-pointer" onClick={() => handleVideoClick(videos[2])}>
          <img
            className="w-full md:w-4/4 max-w-4xl h-auto mt-6"
            src={videos[2]?.cover || '/images/default-cover.jpg'}
            alt="SM Showreel"
            loading="lazy"
          />
          <video
            className="absolute inset-0 w-full h-full object-cover opacity-0 hover:opacity-100 transition-opacity"
            src={videos[2]?.src || ''}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            preload="auto"
            muted
            loading="lazy"
          />
        </div>
        <div className="absolute bottom-16 left-4 md:left-8">
          <h2 className="text-2xl md:text-3xl font-apfel tracking-tighter font-bold">Short Form</h2>
          <p className="text-3xl md:text-5xl font-apfel tracking-tighter font-bold">Videos</p>
        </div>
      </section>

      <section className="flex justify-center bg-black text-white py-4 relative">
        <button
          className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-gradient-to-r from-gray-700 to-gray-900 text-white text-2xl w-12 h-12 flex items-center justify-center rounded-full sm:left-4 md:left-6 lg:left-[5%] z-50 shadow-lg hover:scale-110 hover:shadow-2xl transition-transform duration-300 ease-in-out focus:outline-none"
          onClick={handlePrevSlide}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
          </svg>
        </button>

        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 relative transition-transform duration-500 ease-in-out">
          {visibleVideos().map((video, index) => (
            <div key={index} className="relative w-full sm:w-1/2 md:w-1/2 lg:w-full">
              <video
                className="w-full h-auto max-h-[80vh] object-cover aspect-[9/16] cursor-pointer"
                src={video?.src || ''}
                alt={video?.title || 'Video'}
                onClick={() => handleVideoClick(video)}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                preload="auto"
                muted
                loading="lazy"
              />
              <div className="absolute top-2 right-2">
                <img src="/images/instagram-reel.svg" alt="Reels Icon" className="w-8 h-8" />
              </div>
            </div>
          ))}
        </div>

        <button
          className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-gradient-to-r from-gray-700 to-gray-900 text-white text-2xl w-12 h-12 flex items-center justify-center rounded-full sm:right-4 md:right-6 lg:right-[5%] z-50 shadow-lg hover:scale-110 hover:shadow-2xl transition-transform duration-300 ease-in-out focus:outline-none"
          onClick={handleNextSlide}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </section>
    </>
  );
}
